import {
  addMinutes,
  format,
  formatISO,
  isSameDay,
  parse,
  parseISO
} from 'date-fns';

import { getGMTOffsetFromTimezoneId } from '@/utility/dateHelper';
import { formatDateTimeWithLocale } from '@/utility/localization';

import { PRODUCT_TYPE } from '@/contexts/LibrarySetupContext/constants';

import { PRICE_TYPES } from '@/components/common/PricingSectionAndDiscounts/constants';

import getConstants from './constants';

export const get1on1SessionManagementUrl = ({
  id,
  activeCommunityId = ''
}) => {
  let route = `/portal/products/1on1session/manage/${id}`;
  if (activeCommunityId) {
    route = `${route}?activeCommunityId=${activeCommunityId}`;
  }
  return route;
};

export const getBookingDetails = (
  upcomingBookingData,
  dataIsFromActiveCommunity = false
) => {
  const isOneHourBefore = () => {
    // returns true of session start is less than or equal to one hour from now
    const currentTime = new Date();
    const sessionStartTime = new Date(
      upcomingBookingData.sessionStartTime
    );
    const oneHour = 60 * 60 * 1000; // 1 hour in milliseconds

    const diff = sessionStartTime - currentTime;

    return diff <= oneHour;
  };

  const locationObject = dataIsFromActiveCommunity
    ? upcomingBookingData.location
    : upcomingBookingData.sessionObjectId.location;

  return {
    calendarDetails: {
      title: `${upcomingBookingData.sessionObjectId?.title}`,
      location: locationObject.locationValue,
      startTime: upcomingBookingData?.sessionStartTime,
      endTime: upcomingBookingData?.sessionEndTime
    },
    isOnline: locationObject.type === 'online',
    isOneHourBefore: isOneHourBefore
  };
};

export const getFormDataFrom1on1Session = (session) => {
  const { SESSION_DURATION_OPTIONS } = getConstants();

  const isSessionDurationInOptions = SESSION_DURATION_OPTIONS.some(
    (option) => {
      return option.value === String(session.durationIntervalInMinutes);
    }
  );

  const isFlexiblePriceType =
    session?.pricingConfig?.priceType === PRICE_TYPES.FLEXIBLE;

  if (session.durationIntervalInMinutes)
    return {
      thumbnail: session.thumbnail,
      title: session.title,
      description: session.description,
      durationIntervalInMinutes: isSessionDurationInOptions
        ? String(session.durationIntervalInMinutes)
        : 'custom',
      customDurationIntervalInMinutes: isSessionDurationInOptions
        ? ''
        : String(session.durationIntervalInMinutes),
      amount:
        !isFlexiblePriceType && session?.priceDetails?.amount
          ? session?.priceDetails?.amount / 100
          : 0,
      locationType: session?.location?.type,
      locationValue: session?.location?.locationValue,
      discountsApplied: session?.discountsApplied,
      discountsToDisable: [],
      newDiscountsToApply: [],
      timezoneChosenForAvailability:
        session?.timezoneChosenForAvailability,
      availability: session?.availability,
      applicationConfigDataFields: session?.applicationConfigDataFields,
      resourceSlug: session?.resourceSlug?.replace('/', ''),
      unAvailableDates: session?.unAvailableDates,
      minimumNoticeInDaysForBooking:
        session?.minimumNoticeInDaysForBooking,
      stopAcceptingBookings: session?.stopAcceptingBookings,
      hostInfo: session?.hostInfo,
      status: session?.status,
      showLocation: session?.showLocation,
      type: PRODUCT_TYPE.SESSION,
      priceType: session?.pricingConfig?.priceType,
      minAmount: isFlexiblePriceType
        ? session?.pricingConfig?.minAmount / 100
        : 0,
      suggestedAmount: isFlexiblePriceType
        ? session?.pricingConfig?.suggestedAmount / 100
        : 0
    };
};

export const generateArrayOf24HourTimeFormat30MinIntervals = () => {
  const options = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      // Formatting for 24-hour format
      let formatted24Hour = hour.toString().padStart(2, '0');
      let formattedMinute = minute.toString().padStart(2, '0');
      let time24 = `${formatted24Hour}:${formattedMinute}`;

      // Formatting for 12-hour format with AM/PM
      let hourIn12 = hour % 12 === 0 ? 12 : hour % 12;
      let meridiem = hour < 12 ? 'AM' : 'PM';
      let time12 = `${hourIn12}:${formattedMinute}${meridiem}`;

      options.push({
        value: time24,
        label: time12
      });
    }
  }
  return options;
};

export const generate12HourTimeFormat = (time) => {
  const [hours, minutes] = time.split(':').map(Number);
  const suffix = hours >= 12 ? 'PM' : 'AM';
  const convertedHours = hours % 12 || 12; // Converts "0" hours to "12"
  return `${convertedHours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')} ${suffix}`;
};

export const parseDateDDMMYYYY = (dateStr) => {
  const parts = dateStr.split('/'); // Split the date by '/'
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Subtract 1 because months are 0-indexed
  const year = parseInt(parts[2], 10);
  return new Date(year, month, day);
};

/**
 * Checks if there are any spots available in the given availableSlots.
 * @param {Object} availableSlots - The available slots object.
 * @returns {boolean} - Returns true if there are any spots available, false otherwise.
 */
export const areAnySpotsAvailable = (availableSlots) => {
  // Iterate through each day
  for (const day of availableSlots.days) {
    // Check if any spot has a status of "available"
    if (day.spots.some((spot) => spot.status === 'available')) {
      return true; // If at least one spot is available, return true
    }
  }
  return false; // If no available spots are found, return false
};

/**
 * Formats a 1-on-1 session date with the day of the week.
 *
 * @param {string} dateString - The input date string in the format "dd/MM/yyyy".
 * @returns {Object} - An object containing the formatted date and the day of the week.
 */
export const format1On1SessionDateWithDayOfWeek = (dateString) => {
  // Parse the input date string
  const parsedDate = parse(dateString, 'dd/MM/yyyy', new Date());

  // Format the parsed date to "DD MMM"
  const formattedDate = format(parsedDate, 'dd MMM');

  // Extract the day of the week in short format
  const dayOfWeek = format(parsedDate, 'eee');

  // Return an object with formatted date and day of the week
  return {
    formattedDate,
    dayOfWeek
  };
};

/**
 * Formats the given date and time string to "h:mm a" format.
 *
 * @param {string} inputDateTime - The input date and time string.
 * @returns {string} The formatted time string in "h:mm a" format.
 *
 * @example
 * formatSlotTime('2022-01-01T09:30:00'); // Returns "9:30 AM"
 */
export const formatSlotTime = (inputDateTime) => {
  // Find the last index of '+' or '-'
  const lastPlusIndex = inputDateTime.lastIndexOf('+');
  const lastMinusIndex = inputDateTime.lastIndexOf('-');
  // Find which is the last occurance in the dateString because dateString contains '-' in '2024-02-09'
  const splitIndex =
    lastPlusIndex > lastMinusIndex ? lastPlusIndex : lastMinusIndex;

  // Split the string at the last '+' or '-'
  const inputDateTimeWithoutTz = inputDateTime.substring(0, splitIndex);

  // Parse and format the date
  const parsedDate = parseISO(inputDateTimeWithoutTz);
  const formattedTime = format(parsedDate, 'h:mm a');

  return formattedTime;
};

/**
 * Adds the specified number of minutes to a given date string.
 *
 * @param {string} dateString - The date string to add minutes to.
 * @param {number} minutes - The number of minutes to add.
 * @returns {string} - The updated date string with added minutes.
 */
export const addMinutesToDate = (dateString, minutes) => {
  const date = parseISO(dateString);
  const newDate = addMinutes(date, minutes);
  return formatISO(newDate);
};

export const getEventStartAndEndTimeRange = (event) => {
  try {
    const startTime = new Date(event.startTime);
    const endTime = new Date(event.endTime);
    if (isSameDay(startTime, endTime)) {
      const formattedStart = format(startTime, 'h:mma');
      const formattedEnd = format(endTime, 'h:mma');
      return `${formattedStart} - ${formattedEnd}`;
    } else {
      const formattedStart = format(startTime, 'h:mma');
      const formattedEnd = format(endTime, 'd MMM, h:mma');
      return `${formattedStart} - ${formattedEnd}`;
    }
  } catch (e) {
    return '';
  }
};

/**
 * Calculates the start and end time range of a 1-on-1 session.
 *
 * @param {string} start - The start date and time of the session in ISO 8601 format.
 * @param {string} end - The end date and time of the session in ISO 8601 format.
 * @returns {string} The formatted start and end time range of the session.
 *
 * @example
 * // Same start and end date
 * const start = '2022-01-01T10:00:00Z';
 * const end = '2022-01-01T11:00:00Z';
 * // result: 'Sat, 1 Jan, 10:00 AM - 11:00 AM'
 *
 * @example
 * // Different start and end date
 * const start = '2022-01-01T10:00:00Z';
 * const end = '2022-01-02T11:00:00Z';
 * // result: 'Sat, 1 Jan, 10:00 AM - 2 Jan, 11:00 AM'
 */
export const get1on1SessionStartAndEndTimeRange = (start, end) => {
  const startDate = parseISO(start);
  const endDate = parseISO(end);

  const formattedStart = format(startDate, 'iii, d MMM, h:mm a');
  const formattedEnd = format(endDate, 'h:mm a');

  if (isSameDay(startDate, endDate)) {
    return `${formattedStart} - ${formattedEnd}`;
  } else {
    return `${formattedStart} - ${format(endDate, 'd MMM, h:mm a')}`;
  }
};

export const getSessionBookingStartAndEndTimeRangeWithoutDate = (
  bookingData
) => {
  const { sessionStartTime, sessionEndTime, timezoneOfAttendee } =
    bookingData;
  const startDate = parseISO(sessionStartTime);
  const endDate = parseISO(sessionEndTime);

  const formattedStart = format(startDate, 'h:mm a');
  const formattedEnd = format(endDate, 'h:mm a');

  const gmt = getGMTOffsetFromTimezoneId(timezoneOfAttendee);

  if (isSameDay(startDate, endDate)) {
    return `${formattedStart} - ${formattedEnd} (${gmt})`;
  } else {
    return `${formattedStart} - ${format(
      endDate,
      'd MMM, h:mm a'
    )} (${gmt})`;
  }
};

/**
 * Checks if a one-on-one session has booking questions.
 *
 * @param {Object} oneOnOneSession - The one-on-one session object.
 * @returns {boolean} - Returns true if the session has booking questions, false otherwise.
 */
export const doesSessionHaveBookingQuestions = (oneOnOneSession) => {
  if (!Array.isArray(oneOnOneSession?.applicationConfigDataFields))
    return false;

  const nonDeletedFields =
    oneOnOneSession.applicationConfigDataFields.filter(
      (field) => !field.isDeleted
    );

  return nonDeletedFields?.length > 0;
};

/**
 * Returns the next available session date string.
 * @param {Object} sessionSlots - The session slots object.
 * @returns {string} - The formatted next available session date string.
 * @example
 * const sessionSlots = { days: [ { spots: [ { start_time: '2022-01-01T10:00:00+5:30' } ] } ] };
 * const nextAvailableSessionDateString = getNextAvailableSessionDateString(sessionSlots);
 * // Returns "Sat, 1 Jan, 10:00 AM GMT+5:30"
 */
export const getNextAvailableSessionDateString = (sessionSlots) => {
  try {
    const nextAvailableDateString =
      sessionSlots?.days?.[0]?.spots?.[0]?.start_time;

    if (!nextAvailableDateString) return '';

    const nextAvailableDate = new Date(nextAvailableDateString);

    return formatDateTimeWithLocale(
      nextAvailableDate,
      'iii, d MMM, h:mm a O'
    );
  } catch (e) {
    console.warn(e);
    return '';
  }
};

/**
 * Retrieves slot days grouped by month.
 *
 * @param {Object} sessionSlots - The session slots object.
 * @returns {Array} - An array of objects representing slot days grouped by month.
 *                   Each object in the array has the following shape:
 *                   {
 *                     month: string, // The month name
 *                     days: Array // An array of slot days for the month
 *                   }
 *
 * @example
 * const sessionSlots = {
 *   days: [
 *     { date: '01/01/2022', ... },
 *     { date: '02/01/2022', ... },
 *     { date: '01/02/2022', ... },
 *     ...
 *   ]
 * };
 *
 * const slotDaysByMonth = getSlotDaysByMonth(sessionSlots);
 * // Output:
 * // [
 * //   {
 * //     month: 'January',
 * //     days: [
 * //       { date: '01/01/2022', ... },
 * //       { date: '02/01/2022', ... }
 * //     ]
 * //   },
 * //   {
 * //     month: 'February',
 * //     days: [
 * //       { date: '01/02/2022', ... }
 * //     ]
 * //   },
 * //   ...
 * // ]
 */
export const getSlotDaysByMonth = (sessionSlots) => {
  try {
    const slotsDaysByMonthMap = sessionSlots?.days?.reduce(
      (acc, slotDay) => {
        // const slotDate = parseISO(slotDay.date);
        // Parse the input date string
        const slotDate = parse(slotDay.date, 'dd/MM/yyyy', new Date());

        // Get the month of the slot date
        const month = format(slotDate, 'MMMM');

        if (acc[month]) {
          acc[month].push(slotDay);
        } else {
          acc[month] = [slotDay];
        }

        return acc;
      },
      {}
    );

    const slotsDaysByMonthArr = Object.keys(slotsDaysByMonthMap).map(
      (month) => {
        return {
          month,
          days: slotsDaysByMonthMap[month]
        };
      }
    );

    return slotsDaysByMonthArr;
  } catch (e) {
    return [];
  }
};
