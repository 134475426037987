import { getGMTOffsetFromTimezoneId } from '@/utility/dateHelper';
import {
  DROPDOWN_FIELD_TYPE,
  INLINE_RADIO_FIELD_TYPE,
  NEW_INPUT_FIELD_TYPE,
  TEXTAREA_FIELD_TYPE
} from '@/utility/formHelpers';
import { t } from '@/utility/localization';
import timezoneNames from '@/utility/timezoneNames';

import {
  PRODUCT_ACCESS_FREE,
  PRODUCT_ACCESS_PAID,
  SESSION_1_ON_1_IN_PERSON,
  SESSION_1_ON_1_ONLINE
} from '@/pages/portal/products/constants';

export const getTimeZoneOptions = () =>
  timezoneNames.map((timeZone) => {
    const timeZoneWithoutContinent = timeZone.split('/')?.[1];
    const cleanedTimeZoneWithoutContinent =
      timeZoneWithoutContinent?.replaceAll('_', ' ');
    return {
      label: `${cleanedTimeZoneWithoutContinent} (${getGMTOffsetFromTimezoneId(
        timeZone
      )})`,
      value: timeZone
    };
  });

export default () => {
  const SESSION_DURATION_OPTIONS = [
    { label: t('15-mins'), value: '15' },
    { label: t('30-mins'), value: '30' },
    { label: t('45-mins'), value: '45' },
    { label: t('1-hr'), value: '60' },
    { label: t('custom'), value: 'custom' }
  ];

  const SESSION_TITLE_FIELD = {
    label: t('title'),
    fieldName: 'title',
    fieldDisplayType: NEW_INPUT_FIELD_TYPE,
    inputFieldData: {
      placeholder: t('session-name')
    }
  };

  const SESSION_DESC_FIELD = {
    label: t('description'),
    fieldName: 'description',
    fieldDisplayType: TEXTAREA_FIELD_TYPE,
    inputFieldData: {
      placeholder: t(
        'describe-the-session-they-are-buying-and-why-they-should-buy-it-tip-use-ai-as-a-start-use-emojis-or-bullet-points-to-list-down-whats-included-product-benefits-and-special-offers'
      )
    }
  };

  const SESSION_DURATION_FIELD = {
    fieldName: 'durationIntervalInMinutes',
    fieldDisplayType: DROPDOWN_FIELD_TYPE
  };

  const SESSION_ACCESS_TYPE = {
    fieldDisplayType: INLINE_RADIO_FIELD_TYPE,
    fieldName: 'access',
    options: [
      {
        value: PRODUCT_ACCESS_PAID,
        label: t('paid'),
        testId: 'paid-session'
      },
      {
        value: PRODUCT_ACCESS_FREE,
        label: t('free'),
        testId: 'free-session'
      }
    ]
  };

  const ONE_ON_ONE_SESSION_MANAGEMENT_TABS = [
    { id: 'overview', label: t('overview') },
    { id: 'bookings', label: t('bookings') }
  ];

  const [TAB_OVERVIEW, TAB_BOOKINGS] = ['overview', 'bookings'];

  const ONE_ON_ONE_SESSION_LOCATION_TYPE_LABELS = {
    online: 'Online',
    inPerson: 'In Person'
  };

  const SESSION_LOCATION_FIELD = {
    fieldDisplayType: INLINE_RADIO_FIELD_TYPE,
    fieldName: 'locationType',
    options: [
      {
        value: SESSION_1_ON_1_ONLINE,
        label: t('online'),
        icon: 'globe-02'
      },
      {
        value: SESSION_1_ON_1_IN_PERSON,
        label: t('in-person'),
        icon: 'marker-pin-01'
      }
    ]
  };

  const SESSION_ONLINE_LINK_FIELD = {
    fieldName: 'locationValue',
    fieldDisplayType: NEW_INPUT_FIELD_TYPE,
    inputFieldData: {
      placeholder: t('add-a-recurring-meeting-link-ie-zooms-pmi')
    }
  };
  const SESSION_IN_PERSON_ADDR_FIELD = {
    fieldName: 'locationValue',
    fieldDisplayType: NEW_INPUT_FIELD_TYPE,
    inputFieldData: {
      placeholder: t('add-a-location-for-your-session')
    }
  };

  const CUSTOM_DURATION_INTERVAL_FIELD_NAME =
    'customDurationIntervalInMinutes';

  const ONE_ON_ONE_SESSION_DRAWER_ID = {
    checkout: 'checkout',
    availability: 'availability',
    host: 'host',
    emails: 'emails',
    registrationQuestions: 'registration-questions'
  };

  // DO not convert this to localization
  const AVAILABILITY_DAYS_ID = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ];

  const [AVAILABILITY_TIME_TYPE_FROM, AVAILABILITY_TIME_TYPE_TO] = [
    'from',
    'to'
  ];

  const AVAILABILITY_FIELD_NAME = 'availability';

  const AVAILABILITY_TIMEZONE_FIELD_NAME = 'timezoneChosenForAvailability';

  const UNAVAILABLE_DATE_FIELD_NAME = 'unAvailableDates';

  const MINIMUM_NOTICE_FIELD_NAME = 'minimumNoticeInDaysForBooking';

  const STOP_BOOKINGS_FIELD_NAME = 'stopAcceptingBookings';

  const HOST_SELECT_FIELD = {
    fieldName: 'hostLearnerObjectId',
    label: t('host')
  };

  const HOST_TITLE_FIELD = {
    fieldName: 'hostTitle',
    label: t('host-title'),
    inputFieldData: {
      placeholder: 'Eg. Cofounder of Fitness Club 101 | Fitness enthusiast'
    }
  };

  const HOST_BIO_FIELD = {
    fieldName: 'hostBio',
    label: t('host-bio'),
    inputFieldData: {
      placeholder:
        "Eg. As the Co-founder of 100 Days Challenge, I specialize in creating personalized fitness plans. With over 10 years of experience in health and wellness, I'm passionate about helping others achieve their fitness goals through tailored advice and support."
    }
  };

  return {
    SESSION_DURATION_OPTIONS,
    SESSION_TITLE_FIELD,
    SESSION_DESC_FIELD,
    SESSION_DURATION_FIELD,
    SESSION_ACCESS_TYPE,
    SESSION_LOCATION_FIELD,
    SESSION_ONLINE_LINK_FIELD,
    SESSION_IN_PERSON_ADDR_FIELD,
    CUSTOM_DURATION_INTERVAL_FIELD_NAME,
    ONE_ON_ONE_SESSION_MANAGEMENT_TABS,
    TAB_BOOKINGS,
    TAB_OVERVIEW,
    ONE_ON_ONE_SESSION_LOCATION_TYPE_LABELS,
    ONE_ON_ONE_SESSION_DRAWER_ID,
    AVAILABILITY_DAYS_ID,
    AVAILABILITY_TIME_TYPE_FROM,
    AVAILABILITY_TIME_TYPE_TO,
    getTimeZoneOptions,
    AVAILABILITY_FIELD_NAME,
    AVAILABILITY_TIMEZONE_FIELD_NAME,
    UNAVAILABLE_DATE_FIELD_NAME,
    MINIMUM_NOTICE_FIELD_NAME,
    STOP_BOOKINGS_FIELD_NAME,
    HOST_SELECT_FIELD,
    HOST_TITLE_FIELD,
    HOST_BIO_FIELD
  };
};
